import React from 'react';
import { graphql, Link, navigate } from 'gatsby';


import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../containers/contentfulSectionContainer';
import { PageProps } from './types';
import { Guests } from '@Levi/components/common/list-filters/FilterGuestsForm';
import { addDays, format, startOfToday } from 'date-fns';
import { DateRange, isValidDateRange } from '@Levi/utils/date-utils';
import { encodeAsURIParams } from './utils';
import useContentfulCallToAction, { LinkProps } from '@Levi/hooks/useContentfulCallToAction';
import { localize } from '@Levi/localization/i18n';
import AccommodationSearchBar from '@Levi/components/search/AccommodationSearchBar';
import { useHeaderStoreAPI } from '@Levi/components/header/headerStore';


type ContentfulAccommodationsPageProps = PageProps & {
  data: {
    accommodationSearchPage: {
      id: string;
    };
  };
};


const ContentfulAccommodationPage: React.FC<ContentfulAccommodationsPageProps> = ({ data: {
  contentfulPage: { sections },
  accommodationSearchPage,
} }) => {

  const [guests, setGuests] = React.useState<Guests>({
    adults: 2,
    children: 0,
    infants: 0
  });

  const startDate = addDays(startOfToday(), 3);
  const [dates, setDates] = React.useState<DateRange | undefined>({
    start: startDate,
    end: addDays(startDate, 2)
  });

  const buildParams = React.useCallback(() => {
    const dateParams =
      dates && isValidDateRange(dates)
        ? [`startDate=${format(dates.start, 'yyyy-MM-dd')}`, `endDate=${format(dates.end, 'yyyy-MM-dd')}`]
        : [];

    const guestParams =
      guests.adults > 0 || guests.children > 0
        ? [`numAdults=${encodeAsURIParams(guests.adults)}`, `numChildren=${guests.children}`, `numInfants=${guests.infants}`]
        : [];

    const paramsString = [...dateParams, ...guestParams]
      .filter((params) => !!params)
      .join('&');
    if (paramsString) {
      return `#!/?${paramsString}`;
    }
    return '';
  }, [dates, guests]);


  const accommodationSearchPageLink = useContentfulCallToAction({
    destination: {
      pageId: accommodationSearchPage.id,
    },
    target: 'self',
    title: localize('common.showAll'),
  }) as LinkProps<typeof Link>;


  const onSubmitSearch = React.useCallback(() => {
    if (accommodationSearchPageLink) {
      navigate(accommodationSearchPageLink.to + buildParams());
    }
  }, [accommodationSearchPageLink, buildParams]);


  const renderProp = React.useMemo(
    () => (
      <AccommodationSearchBar
        key="accommodation-search-bar"
        onGuestsChange={setGuests}
        guests={guests}
        dates={dates}
        onDatesChange={setDates}
        onSubmit={onSubmitSearch}
      />
    ),
    [dates, guests, onSubmitSearch],
  );

  const api = useHeaderStoreAPI();
  React.useEffect(() => {
    api.setState({ renderProp });

    return () => {
      api.setState({ renderProp: undefined });
    };
  }, [api, renderProp]);



  return (
    <main>
      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}
    </main>
  );
};


export default ContentfulAccommodationPage;


export const contentfulPageQuery = graphql`
  query AccommodationPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
      analyticsTags
    }
    accommodationSearchPage: contentfulPage(
       slug: { in: ["accommodation-search", "majoitushaku"] }
       node_locale: { eq: $locale }
    ) {
       id
    }  
  }
`;